'use client';

import React from 'react';
import styled from 'styled-components';
import {
  StructuredText as StructuredTextBase,
  type StructuredTextGraphQlResponse,
  renderNodeRule,
} from 'react-datocms';
import {
  renderRule,
  isParagraph,
  isLink,
  isHeading,
} from 'datocms-structured-text-utils';
import Link from 'next/link';
import { H1, H2, H3, H4, BodyText } from '@/lib/StyledComponents/Typography';
import { mq } from '@/lib/StyledComponents/mq';

const StructuredTextWrapper = styled.div`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    padding: revert;
    margin-bottom: 1rem;
  }

  > p {
    margin: revert;
    padding: revert;
  }

  b,
  strong,
  em,
  i {
    font-style: revert;
  }

  > ul {
    list-style: disc;
  }

  > ul,
  ol {
    margin: 1rem 0;
    padding: revert;

    li > p {
      margin: revert;
      padding: revert;
    }
  }
`;

const StyledBodyText = styled(BodyText)`
  ${mq('tablet')} {
    font-size: 1rem;
  }
`;

export type StructuredTextProps = {
  data?: StructuredTextGraphQlResponse | null;
};

const StructuredText = ({ data }: StructuredTextProps) => {
  return (
    <StructuredTextWrapper>
      <StructuredTextBase
        data={data}
        customNodeRules={[
          renderNodeRule(isLink, ({ node, children, key }) => {
            const metaProps: Record<string, string> = {};
            node?.meta?.forEach(({ id, value }) => {
              metaProps[id] = value;
            });
            return (
              <Link key={key} href={node.url} {...metaProps}>
                {children}
              </Link>
            );
          }),
          renderNodeRule(
            isHeading,
            ({ adapter: { renderNode }, node, children, key }) => {
              const getHeadingByNodeLevel = () => {
                switch (node.level) {
                  case 1:
                    return H1;
                  case 2:
                    return H2;
                  case 3:
                    return H3;
                  case 4:
                  case 5:
                  case 6:
                    return H4;
                }
              };

              return renderNode(
                getHeadingByNodeLevel(),
                {
                  key,
                  as: `h${node.level}`,
                },
                children
              );
            }
          ),
          renderRule(
            isParagraph,
            ({ adapter: { renderNode }, children, key }) => {
              return renderNode(StyledBodyText, { key }, children);
            }
          ),
        ]}
      />
    </StructuredTextWrapper>
  );
};

export default StructuredText;
