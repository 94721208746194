'use client';

import { type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { LinkButton } from './LinkButton';
import { type HeroBlockData } from '@/schema/cms/blocks/HeroBlock.schema';
import { mq } from '@/lib/StyledComponents/mq';
import { H1 } from '@/lib/StyledComponents/Typography';
import {
  Image as CmsImage,
  type StructuredTextGraphQlResponse,
} from 'react-datocms';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import StructuredText from '../shared/StructuredText';

type BasicMediaType = {
  $bottomLinearGradient: boolean;
  $height?: number;
};

const BasicMedia = styled.div<BasicMediaType>`
  position: relative;
  margin-top: -5rem;

  height: 26rem;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme }) =>
      css`linear-gradient(0deg, ${theme.colors.background.dark1} 0, transparent)`};

    ${mq('laptop')} {
      background: ${({ theme, $bottomLinearGradient }) =>
        css`linear-gradient(${$bottomLinearGradient ? '0' : '90'}deg, ${theme.colors.background.dark1} 0, transparent)`};
    }
  }

  ${mq('laptop')} {
    margin-top: 0;
    height: 25rem;
  }

  ${mq('laptopL')} {
    margin-top: 0;
    height: 35rem;
  }

  ${mq('laptopXL')} {
    margin-top: 0;
    height: 40rem;
  }
`;

const alignFlex = ({ $align }: { $align: HeroProps['alignContent'] }) => {
  switch ($align) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

type BasicTextType = {
  $align: HeroProps['alignContent'];
  $valuationResultPage: boolean;
};

const BasicText = styled.div<BasicTextType>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  text-align: center;

  margin-top: ${({ $valuationResultPage }) =>
    $valuationResultPage ? '-16' : '-9'}rem;
  padding: 0 1.75rem 1.75rem;
  padding-bottom: 6.25rem;
  z-index: 2;

  h5 {
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  ${mq('laptop')} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: ${alignFlex};

    text-align: ${({ $align }) => `${$align || 'left'}`};
    max-width: 90rem;
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 1rem;

    h1,
    h2,
    h3,
    h4 {
      width: 90%;
      margin: 0;
      margin-bottom: 1rem;
      filter: drop-shadow(5px 5px 10px #000000a8);
    }

    h5 {
      margin-bottom: 1.625rem;
    }

    h1 {
      font-size: 3rem;
    }
  }

  ${mq(null, 'laptop')} {
    padding-bottom: ${({ $valuationResultPage }) =>
      $valuationResultPage ? '8' : '1.75'}rem;
  }
`;

const LinksWrapper = styled.div<{ $align: HeroProps['alignContent'] }>`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  align-items: center;
  justify-items: center;

  ${mq('laptop')} {
    justify-content: ${alignFlex};
    align-items: ${({ $align }) => `${$align || 'left'}`};
  }
`;

const DescriptionWrapper = styled.div`
  margin-top: 1.5rem;

  ${mq('laptop')} {
    margin-top: 2rem;
  }
`;

type HeroProps = PropsWithChildren<
  Pick<
    HeroBlockData,
    'title' | 'subtitle' | 'linkButtons' | 'alignContent' | 'description'
  > & {
    image: Partial<HeroBlockData['image']>;
    bottomLinearGradient?: boolean;
    height?: number;
    valuationResultPage?: boolean;
  }
>;

export const Hero = ({
  title,
  subtitle,
  image,
  linkButtons,
  alignContent,
  description,
  children,
  bottomLinearGradient = false,
  height,
  valuationResultPage = false,
}: HeroProps) => {
  if (!title && !subtitle && !image && !linkButtons) return null;

  return (
    <>
      {image?.responsiveImage && (
        <BasicMedia
          $bottomLinearGradient={bottomLinearGradient}
          $height={height}
        >
          <CmsImage
            data={image?.responsiveImage}
            objectFit="cover"
            style={{ position: 'static', height: '100%' }}
            placeholderStyle={{
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
          />
        </BasicMedia>
      )}
      <BasicText
        $align={alignContent}
        $valuationResultPage={valuationResultPage}
      >
        <H1>{title}</H1>
        {subtitle && <h5>{subtitle}</h5>}
        {linkButtons && linkButtons?.length > 0 && (
          <LinksWrapper $align={alignContent}>
            {linkButtons?.map(linkButton => (
              <LinkButton
                href={linkButton.url}
                key={linkButton.url}
                $textColor={linkButton.textColorNew}
                $backgroundColor={linkButton.backgroundColorNew}
                $variant={linkButton.variant}
              >
                {linkButton.label}
              </LinkButton>
            ))}
          </LinksWrapper>
        )}
        {children}
        {!isEmptyDocument(description) && (
          <DescriptionWrapper>
            <StructuredText
              data={description as StructuredTextGraphQlResponse}
            />
          </DescriptionWrapper>
        )}
      </BasicText>
    </>
  );
};
