'use client';

import styled, { css } from 'styled-components';

export type CardProps = {
  $ignoreCenterAlign?: boolean;
};

export const CardStyle = css<CardProps>`
  position: relative;

  padding: 1.88rem;

  display: flex;
  flex-direction: column;
  ${({ $ignoreCenterAlign: ignoreCenterAlign }) =>
    !ignoreCenterAlign &&
    css`
      align-items: center;
    `}
  gap: 1rem;

  background-color: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.dark};

  border-radius: 0.625rem;
  box-shadow: 0px 4px 10px 0px #00000033;
`;

export const Card = styled.div<CardProps>`
  ${CardStyle}
`;
